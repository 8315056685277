<script>
	import {
		Carousel,
		Slide
	} from "vue-carousel";
	import indexApi from "@/apis/indexApi"
	import {
		UserIcon,
		MailIcon,
		KeyIcon,
		ArrowUpIcon,
		HelpCircleIcon,
	} from "vue-feather-icons";

	import Switcher from "@/components/switcher";
	// import Footer from "@/components/footer";
	import referralApi from "@/apis/referralApi";
	import storage from '../storage';
	import Features from "@/components/features";
	import Testimonial from "@/components/testimonial";
	import date, {parseTime} from "../date";
	import Navbar2 from "../components/navbar2.vue";
	import blogApi from '../apis/blogApi';
	// import Navbar from "../components/navbar.vue";
	import {Message} from "element-ui"

  import Navbar from "@/views/new-view/components/navbar";
  import Footer from "@/views/new-view/components/footerBar";
	/**
	 * Index-1
	 */
	export default {
    metaInfo: {
      title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
      link: [
        { rel: 'canonical', href: 'https://inccrypto.com/reviews' }
      ]
    },
		data() {
			return {
				data: {
					title: "",
					longDesc: ""
				},
				login: false,
				load: false,
				value1: 5,
				selectModel: {
					pageNum:1,
					pageSize:5
				},
				saveModel: {
					content: "",
					createdAt: "",
					email: "",
					// id: 0,
					ratings: 0,
					title: "",
					type: 1
				},
				dataList:[],
				limt:1
			}
		},
    filters: {parseTime},
		components: {
			Switcher,
			Carousel,
			Slide,
			UserIcon,
			MailIcon,
			KeyIcon,
			ArrowUpIcon,
			Footer,
			HelpCircleIcon,
			Features,
			Testimonial,
			Navbar2,
			Navbar
		},
		created() {
			this.selectList()
		},
		methods: {
			selectList(){
				indexApi.GetComments(
					this.selectModel,
					res=>{
						this.dataList.length=0
						this.dataList=res.data.result
						this.dataList.forEach(i=>{
							i.createdAt=i.createdAt.split('T')[0].replace('-','/')
						})
						this.limt=res.data.totalPages
					}
				)
			},
			saveComment(){
				if(this.saveModel.content==""||this.saveModel.email==""||this.saveModel.title==""){
					Message({
						message:"Please enter to fill in all data",
						type:"error"
					})
					return
				}
				if(this.saveModel.email.indexOf('@')==-1){
					Message({
						message:"Please enter the correct email address",
						type:"error"
					})
					return
				}
				this.saveModel.createdAt = new Date().toISOString()
				var savedata = JSON.parse(JSON.stringify(this.saveModel))
				savedata.content = "<p>"+this.saveModel.content+"</p>"
				indexApi.SetComment({
					data:savedata
				}, res=>{
          console.log(res)
          Message({
            message:"success",
            type:"success"
          })
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
			},
			Preview(){
				if(this.selectModel.pageNum==1){
					Message({
						message:"It's the top",
						type:"error"
					})
					return
				}
				this.selectModel.pageNum--
				this.selectList()
			},
			Next(){
				if(this.selectModel.pageNum==this.limt){
					Message({
						message:"No more can be found",
						type:"error"
					})
					return
				}
				this.selectModel.pageNum++
				this.selectList()
			}
		}
	};
</script>

<template>
	<div>
		<Navbar v-if="!login" />
		<Navbar2 v-else />
		<!-- 顶部 -->
		<section class="bg-half d-table w-100" style="padding-top: 100px;">
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<div class="section-title mb-4 pb-2">
						<h4 class="title mb-4">Our Customers Say</h4>
						<p class="text-muted para-desc mx-auto mb-0">
							We have many happy investors invest with us.
							Some impresions from our Customers!
							Please read some of the lovely things our Customers
							say about us.
						</p>
					</div>
				</div>
			</div>
		</section>

		<!--Shape End-->
		<section class="section pt-0">
			<div class="container">
				<div class="p-3 border-top" v-for="i in dataList" :key="i">
					<div style="display: flex;flex-flow: row nowrap;justify-content: space-between;">
						<span style="font-weight: bold;">{{i.email}}</span>
						<el-rate v-model="i.ratings" disabled></el-rate>
					</div>
					<div style="color: gray;font-size: 14px;"><i class="el-icon-time"></i>{{i.createdAt  | parseTime('{d}/{m}/{y}') }}</div>
					<div class="p" style="font-weight: bold;">{{i.title}}</div>
					<div class="p" v-html="i.content">

					</div>
				</div>
			</div>
			<div class="container">
				<div
					style="border: 1px #efefef solid;border-radius: 10px;width: 220px;padding: 15px 15px;margin-top: 10px;margin: 10px auto;">
					<span @click="Preview"
						style="padding: 5px 10px;background-color: #f9f9f9;color: black;border-radius: 8px;font-weight: bold;cursor: pointer;user-select: none;">
						<i class="el-icon-back" style="margin-right: 3px;"></i>Previous
					</span>
					<span @click="Next"
						style="padding: 5px 10px;background-color: #f9f9f9;color: black;border-radius: 8px;margin-left: 5px;font-weight: bold;cursor: pointer;user-select: none;">
						Next<i class="el-icon-right" style="margin-left: 3px;"></i>
					</span>
				</div>
			</div>
			<div class="container p-3">
				<label style="font-weight: bold;font-size: 18px;">Leave A Review</label>
				<div>
					<label style="font-weight: bold;font-size: 14px;">Email</label>
					<el-input v-model="saveModel.email"></el-input>
					<label style="font-weight: bold;font-size: 14px;">Source</label>
					<el-rate v-model="saveModel.ratings"></el-rate>
					<label style="font-weight: bold;font-size: 14px;">Title</label>
					<el-input v-model="saveModel.title"></el-input>
					<label style="font-weight: bold;font-size: 14px;">Review</label>
					<el-input type="textarea" v-model="saveModel.content" :rows="4">
					</el-input>
				</div>
				<el-button style="border-radius: 10px;margin-top: 20px;" @click="saveComment" type="primary">SUBMIT</el-button>
			</div>
		</section>


		<Footer />
		<!-- Footer End -->
<!--		<Switcher />-->
		<!-- Back to top -->
		<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
			v-scroll-to="'#topnav'">
			<arrow-up-icon class="icons"></arrow-up-icon>
		</a>
		<!-- Back to top -->
	</div>
</template>

<style scoped>
	.p {
		white-space: pre-wrap;
		word-wrap: break-word;
		word-break: break-all;
	}
</style>
